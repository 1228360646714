.modal-content {
    padding: 50px !important;
}

/* .fade{
    padding-left: 0px !important;
} */

@media (min-width: 991px) {
    /* .modal-content {
        height: 100vh !important;
        padding: 50px !important;
        box-sizing: border-box;
    } */
}