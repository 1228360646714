/* @font-face {
  font-family: 'DM Sans', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@1,700&display=swap');
} */

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cus-font-family{
  /* font-family: 'Poppins', sans-serif !important; */
  /* font-family: 'Lilita One', cursive !important; */
  font-family: 'DM Sans', sans-serif !important;
  font-weight: normal;
  font-size: 20px;
}

.common-font-family{
  font-family: 'DM Sans', sans-serif !important;
}