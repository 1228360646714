/* Deafult Margin & Padding */

/*-- Margin Top --*/

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-55 {
	margin-top: 55px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-65 {
	margin-top: 65px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-75 {
	margin-top: 75px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-85 {
	margin-top: 85px;
}

.mt-90 {
	margin-top: 90px;
}

.mt-95 {
	margin-top: 95px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-105 {
	margin-top: 105px;
}

.mt-110 {
	margin-top: 110px;
}

.mt-115 {
	margin-top: 115px;
}

.mt-120 {
	margin-top: 120px;
}

.mt-125 {
	margin-top: 125px;
}

.mt-130 {
	margin-top: 130px;
}

.mt-135 {
	margin-top: 135px;
}

.mt-140 {
	margin-top: 140px;
}

.mt-145 {
	margin-top: 145px;
}

.mt-150 {
	margin-top: 150px;
}

.mt-155 {
	margin-top: 155px;
}

.mt-160 {
	margin-top: 160px;
}

.mt-165 {
	margin-top: 165px;
}

.mt-170 {
	margin-top: 170px;
}

.mt-175 {
	margin-top: 175px;
}

.mt-180 {
	margin-top: 180px;
}

.mt-185 {
	margin-top: 185px;
}

.mt-190 {
	margin-top: 190px;
}

.mt-195 {
	margin-top: 195px;
}

.mt-200 {
	margin-top: 200px;
}

/*-- Margin Bottom --*/

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-35 {
	margin-bottom: 35px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-55 {
	margin-bottom: 55px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-65 {
	margin-bottom: 65px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-75 {
	margin-bottom: 75px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-85 {
	margin-bottom: 85px;
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-95 {
	margin-bottom: 95px;
}

.mb-100 {
	margin-bottom: 100px;
}

.mb-105 {
	margin-bottom: 105px;
}

.mb-110 {
	margin-bottom: 110px;
}

.mb-115 {
	margin-bottom: 115px;
}

.mb-120 {
	margin-bottom: 120px;
}

.mb-125 {
	margin-bottom: 125px;
}

.mb-130 {
	margin-bottom: 130px;
}

.mb-135 {
	margin-bottom: 135px;
}

.mb-140 {
	margin-bottom: 140px;
}

.mb-145 {
	margin-bottom: 145px;
}

.mb-150 {
	margin-bottom: 150px;
}

.mb-155 {
	margin-bottom: 155px;
}

.mb-160 {
	margin-bottom: 160px;
}

.mb-165 {
	margin-bottom: 165px;
}

.mb-170 {
	margin-bottom: 170px;
}

.mb-175 {
	margin-bottom: 175px;
}

.mb-180 {
	margin-bottom: 180px;
}

.mb-185 {
	margin-bottom: 185px;
}

.mb-190 {
	margin-bottom: 190px;
}

.mb-195 {
	margin-bottom: 195px;
}

.mb-200 {
	margin-bottom: 200px;
}

/*-- Padding Top --*/

.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-55 {
	padding-top: 55px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-65 {
	padding-top: 65px;
}

.pt-70 {
	padding-top: 70px;
}

.pt-75 {
	padding-top: 75px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-85 {
	padding-top: 85px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-95 {
	padding-top: 95px;
}

.pt-100 {
	padding-top: 100px;
}

.pt-105 {
	padding-top: 105px;
}

.pt-110 {
	padding-top: 110px;
}

.pt-115 {
	padding-top: 115px;
}

.pt-120 {
	padding-top: 120px;
}

.pt-125 {
	padding-top: 125px;
}

.pt-130 {
	padding-top: 130px;
}

.pt-135 {
	padding-top: 135px;
}

.pt-140 {
	padding-top: 140px;
}

.pt-145 {
	padding-top: 145px;
}

.pt-150 {
	padding-top: 150px;
}

.pt-155 {
	padding-top: 155px;
}

.pt-160 {
	padding-top: 160px;
}

.pt-165 {
	padding-top: 165px;
}

.pt-170 {
	padding-top: 170px;
}

.pt-175 {
	padding-top: 175px;
}

.pt-180 {
	padding-top: 180px;
}

.pt-185 {
	padding-top: 185px;
}

.pt-190 {
	padding-top: 190px;
}

.pt-195 {
	padding-top: 195px;
}

.pt-200 {
	padding-top: 200px;
}
.pt-220 {
	padding-top: 220px;
}

/*-- Padding Bottom --*/

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-45 {
	padding-bottom: 45px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-55 {
	padding-bottom: 55px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-65 {
	padding-bottom: 65px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-75 {
	padding-bottom: 75px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-85 {
	padding-bottom: 85px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-95 {
	padding-bottom: 95px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pb-105 {
	padding-bottom: 105px;
}

.pb-110 {
	padding-bottom: 110px;
}

.pb-115 {
	padding-bottom: 115px;
}

.pb-120 {
	padding-bottom: 120px;
}

.pb-125 {
	padding-bottom: 125px;
}

.pb-130 {
	padding-bottom: 130px;
}

.pb-135 {
	padding-bottom: 135px;
}

.pb-140 {
	padding-bottom: 140px;
}

.pb-145 {
	padding-bottom: 145px;
}

.pb-150 {
	padding-bottom: 150px;
}

.pb-155 {
	padding-bottom: 155px;
}

.pb-160 {
	padding-bottom: 160px;
}

.pb-165 {
	padding-bottom: 165px;
}

.pb-170 {
	padding-bottom: 170px;
}

.pb-175 {
	padding-bottom: 175px;
}

.pb-180 {
	padding-bottom: 180px;
}

.pb-185 {
	padding-bottom: 185px;
}

.pb-190 {
	padding-bottom: 190px;
}

.pb-195 {
	padding-bottom: 195px;
}

.pb-200 {
	padding-bottom: 200px;
}

/*-- Padding Left --*/

.pl-0 {
	padding-left: 0px;
}

.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-25 {
	padding-left: 35px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-45 {
	padding-left: 45px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-55 {
	padding-left: 55px;
}

.pl-60 {
	padding-left: 60px;
}

.pl-65 {
	padding-left: 65px;
}

.pl-70 {
	padding-left: 70px;
}

.pl-75 {
	padding-left: 75px;
}

.pl-80 {
	padding-left: 80px;
}

.pl-85 {
	padding-left: 80px;
}

.pl-90 {
	padding-left: 90px;
}

.pl-95 {
	padding-left: 95px;
}

.pl-100 {
	padding-left: 100px;
}
.pl-105 {
	padding-left: 105px;
}
.pl-110 {
	padding-left: 110px;
}
.pl-115 {
	padding-left: 115px;
}
.pl-120 {
	padding-left: 120px;
}
.pl-125 {
	padding-left: 125px;
}
.pl-130 {
	padding-left: 130px;
}
.pl-135 {
	padding-left: 135px;
}
.pl-140 {
	padding-left: 140px;
}
.pl-145 {
	padding-left: 145px;
}
.pl-150 {
	padding-left: 150px;
}
.pl-155 {
	padding-left: 155px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-160 {
	padding-left: 160px;
}
.pl-165 {
	padding-left: 165px;
}
.pl-170 {
	padding-left: 170px;
}
.pl-175 {
	padding-left: 175px;
}
.pl-180 {
	padding-left: 180px;
}
.pl-185 {
	padding-left: 185px;
}
.pl-190 {
	padding-left: 190px;
}
.pl-195 {
	padding-left: 195px;
}
.pl-200 {
	padding-left: 200px;
}

/*-- Padding Right --*/

.pr-0 {
	padding-right: 0px;
}

.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-25 {
	padding-right: 35px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-45 {
	padding-right: 45px;
}

.pr-50 {
	padding-right: 50px;
}

.pr-55 {
	padding-right: 55px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-65 {
	padding-right: 65px;
}

.pr-70 {
	padding-right: 70px;
}

.pr-75 {
	padding-right: 75px;
}

.pr-80 {
	padding-right: 80px;
}

.pr-85 {
	padding-right: 80px;
}

.pr-90 {
	padding-right: 90px;
}

.pr-95 {
	padding-right: 95px;
}

.pr-100 {
	padding-right: 100px;
}
.pr-105 {
	padding-right: 105px;
}
.pr-110 {
	padding-right: 110px;
}
.pr-115 {
	padding-right: 115px;
}
.pr-120 {
	padding-right: 120px;
}
.pr-125 {
	padding-right: 125px;
}
.pr-130 {
	padding-right: 130px;
}
.pr-135 {
	padding-right: 135px;
}
.pr-140 {
	padding-right: 140px;
}
.pr-145 {
	padding-right: 145px;
}
.pr-150 {
	padding-right: 150px;
}
.pr-155 {
	padding-right: 155px;
}
.pr-160 {
	padding-right: 160px;
}
.pr-165 {
	padding-right: 165px;
}
.pr-170 {
	padding-right: 170px;
}
.pr-175 {
	padding-right: 175px;
}
.pr-180 {
	padding-right: 180px;
}
.pr-185 {
	padding-right: 185px;
}
.pr-190 {
	padding-right: 190px;
}
.pr-195 {
	padding-right: 195px;
}
.pr-120 {
	padding-right: 120px;
}

/* font weight */

.f-700 {
	font-weight: 700;
}

.f-600 {
	font-weight: 600;
}

.f-500 {
	font-weight: 500;
}

.f-400 {
	font-weight: 400;
}

.f-300 {
	font-weight: 300;
}

/* Background Color */

.gray_bg {
	background: #faf7f2;
}

.white_bg {
	background: #fff;
}
.black_thm_bg {
	background: #1b2026;
}

.theme_bg {
	background: #ff8e28;
}

.primary_bg {
	background: #222;
}

.footer_bg {
	background: #1e1d23;
}

/* Color */

.white-color {
	color: #fff;
}

.black-color {
	color: #222;
}

.theme-color {
	color: #222;
}

.primary-color {
	color: #222;
}

/* black overlay */
[data-overlay] {
	position: relative;
}

[data-overlay]::before {
	background: #000 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

[data-overlay="3"]::before {
	opacity: 0.3;
}

[data-overlay="4"]::before {
	opacity: 0.4;
}

[data-overlay="5"]::before {
	opacity: 0.5;
}

[data-overlay="6"]::before {
	opacity: 0.6;
}

[data-overlay="7"]::before {
	opacity: 0.7;
}

[data-overlay="8"]::before {
	opacity: 0.8;
}

[data-overlay="9"]::before {
	opacity: 0.9;
}

.pos-rel {
	position: relative;
}

/* back to top start */
#thetop {
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	position: absolute;
}
#backtotop {
	right: 15px;
	width: 40px;
	height: 40px;
	z-index: 999;
	bottom: 70px;
	display: none;
	position: fixed;
	border-radius: 100%;
	background-color: #fff;
	box-shadow: 0px 10px 30px 1px rgba(0, 0, 0, 0.15);
}

#backtotop #scroll {
	z-index: 1;
	width: 100%;
	height: 100%;
	color: #0c0c0c;
	display: block;
	font-size: 15px;
	overflow: hidden;
	line-height: 42px;
	text-align: center;
	position: relative;
}
#backtotop #scroll i {
	left: 50%;
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#backtotop #scroll i:nth-child(1) {
	top: 53%;
	-webkit-transform: translate(-50%, -53%);
	-ms-transform: translate(-50%, -53%);
	transform: translate(-50%, -53%);
}

#backtotop #scroll i:nth-child(2) {
	top: 100%;
	opacity: 0;
}

#backtotop #scroll:hover i:nth-child(1) {
	top: -100%;
	opacity: 0;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

#backtotop #scroll:hover i:nth-child(2) {
	top: 53%;
	opacity: 1;
	-webkit-transform: translate(-50%, -53%);
	-ms-transform: translate(-50%, -53%);
	transform: translate(-50%, -53%);
}

/* play btn animation */
.video_icon {
	position: absolute;
	background: #ffffff;
	color: #000;
	height: 90px;
	width: 90px;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	font-size: 20px;
	line-height: 94px;
	transform: translateY(-50%);
	border-radius: 50%;
	-webkit-border-radius: -50%;
	-moz-border-radius: -50%;
	-ms-border-radius: -50%;
	-o-border-radius: -50%;
}

.video_icon:hover {
	color: #000;
}

.video_icon::before, .video_icon::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 50%;
	background-color: rgb(255, 255, 255);
	opacity: 0.15;
	z-index: -10;
}

.video_icon::before {
	z-index: -10;
	-webkit-animation: inner-ripple 2000ms linear infinite;
	-moz-animation: inner-ripple 2000ms linear infinite;
	animation: inner-ripple 2000ms linear infinite;
}

.video_icon::after {
	z-index: -10;
	-webkit-animation: outer-ripple 2000ms linear infinite;
	-moz-animation: outer-ripple 2000ms linear infinite;
	animation: outer-ripple 2000ms linear infinite;
}
/* animation */
@keyframes outer-ripple {
	0% {
	  transform: scale(1);
	  filter: alpha(opacity=50);
	  opacity: 0.5;
	  -webkit-transform: scale(1);
	  -moz-transform: scale(1);
	  -ms-transform: scale(1);
	  -o-transform: scale(1);
	  -webkit-filter: alpha(opacity=50);
	}
	80% {
	  transform: scale(1.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(1.5);
	  -moz-transform: scale(1.5);
	  -ms-transform: scale(1.5);
	  -o-transform: scale(1.5);
	}
	100% {
	  transform: scale(2.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(2.5);
	  -moz-transform: scale(2.5);
	  -ms-transform: scale(2.5);
	  -o-transform: scale(2.5);
	}
}
@-webkit-keyframes outer-ripple {
	0% {
	  transform: scale(1);
	  filter: alpha(opacity=50);
	  opacity: 0.5;
	  -webkit-transform: scale(1);
	  -moz-transform: scale(1);
	  -ms-transform: scale(1);
	  -o-transform: scale(1);
	}
	80% {
	  transform: scale(2.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(2.5);
	  -moz-transform: scale(2.5);
	  -ms-transform: scale(2.5);
	  -o-transform: scale(2.5);
	}
	100% {
	  transform: scale(3.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(3.5);
	  -moz-transform: scale(3.5);
	  -ms-transform: scale(3.5);
	  -o-transform: scale(3.5);
	}
}
@-moz-keyframes outer-ripple {
	0% {
	  transform: scale(1);
	  filter: alpha(opacity=50);
	  opacity: 0.5;
	  -webkit-transform: scale(1);
	  -moz-transform: scale(1);
	  -ms-transform: scale(1);
	  -o-transform: scale(1);
	}
	80% {
	  transform: scale(2.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(2.5);
	  -moz-transform: scale(2.5);
	  -ms-transform: scale(2.5);
	  -o-transform: scale(2.5);
	}
	100% {
	  transform: scale(3.5);
	  filter: alpha(opacity=0);
	  opacity: 0;
	  -webkit-transform: scale(3.5);
	  -moz-transform: scale(3.5);
	  -ms-transform: scale(3.5);
	  -o-transform: scale(3.5);
	}
}
/* order & unorder list reset - start */
.ul_li, .ul_li_right, .ul_li_center {
	margin: 0px;
	padding: 0px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.ul_li>li, .ul_li_right>li, .ul_li_center>li {
	float: left;
	list-style: none;
	display: inline-block;
}

.ul_li {
	justify-content: flex-start;
}

.ul_li_center {
	justify-content: center;
}

.ul_li_right {
	justify-content: flex-end;
}

.ul_li_block {
	margin: 0px;
	padding: 0px;
	display: block;
}

.ul_li_block > li {
	display: block;
	list-style: none;
}
/* section title */
.sec_title {
	margin-bottom: 20px;
}
.sec_title > span {
	font-size: 22px;
	color: #ff8e28;
	margin-bottom: 16px;
	font-family: 'Sofia', cursive;
}
.sec_title > h2 {
	color: #1e1d23;
	font-size: 45px;
	line-height: 1.2;
	text-transform: uppercase;
	margin-bottom: 22px;
}
.sec_title-2 {
	text-align: center;
	margin: 0 auto;
	margin-bottom: 50px;
}
.sec_title-white > h2 {
	color: #fff;
}
.sec_title-white > p {
	color: #d9d9d9;
}
/* pagination */
.pagination_wrap ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: -5px;
}

.pagination_wrap ul li {
	padding: 5px;
}

.pagination_wrap ul a {
	height: 50px;
	width: 50px;
	line-height: 50px;
	background-color: #fff;
	font-size: 15px;
	color: #777777;
	text-align: center;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	border-radius: 6px;
	box-shadow: 0px 12px 8px 0px rgba(213, 151, 96, 0.06);
}

.pagination_wrap ul a i {
	font-size: 17px;
}

.pagination_wrap ul a:hover, .pagination_wrap ul a.current_page {
	background-color: #ff8e28;
	color: #fff;
}
/* social */
.social_links a {
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 35px;
	font-size: 14px;
	color: #b8b8b8;
	background: #232228;
	display: inline-block;
	border-radius: 5px;
	margin-right: 5px;
}
.social_links a:last-child {
	margin-right: 0;
}
.social_links a:hover {
	background-color: #ff8e28;
	color: #fff;
}